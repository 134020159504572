<template>
  <div>
    <b-button
      variant="link"
      class="filter-button"
      v-b-modal.filters-modal><span class="filter-button-text">Filter </span><font-awesome-icon :icon="['fa-solid', 'filter']"/></b-button>

    <b-modal id="filters-modal" centered scrollable size="xl">
      <template #modal-title>
        <h5 class="font-weight-bold text-dark">Filters</h5>
      </template>

      <div class="row">
        <div class="col-md-6 mb-4">
          <label for="">Country / Region</label>

          <ifac-search-dropdown
            :options="countries"
            placeholder="Select Country / Region"
            :current="currentSelectedCountry"
            currentType="object"
            currentValue="name"
            optionValue="code"
            optionTitle="name"
            searchAgainst="name"
            emitType="string"
            emitValue="code"
            showClear
            @selected="filters.countries = $event"
          ></ifac-search-dropdown>
        </div>

        <div class="col-md-6 mb-4">
          <label for="">Industry / Academia / Government</label>
          <ifac-search-dropdown
            :options="iags"
            placeholder="Select Industry / Academia / Government"
            :current="currentSelectedIag"
            currentType="object"
            currentValue="name"
            optionValue="name"
            optionTitle="name"
            searchAgainst="name"
            emitType="string"
            emitValue="name"
            showClear
            @selected="filters.iags = $event ? $event.toLowerCase() : ''"
          ></ifac-search-dropdown>
        </div>
      </div>

      <!--  -->

      <fields-of-interest
        :selected="filters.fieldsOfInterest"
        @selected="toggleInterest"
      ></fields-of-interest>

      <!--  -->

      <template #modal-footer="{ close }">
        <div class="w-100">
          <b-button
              variant="link"
              class="float-left"
              @click="close()"
            >
              Cancel
            </b-button>
          <div class="float-right d-flex justify-content-end align-items-center">
            <div
              class="selected-filters-count"
              v-if="filters.fieldsOfInterest.length">
              {{ `${filters.fieldsOfInterest.length} Technical Committees selected` }}
            </div>
            <b-button
              variant="outline-light"
              @click="initFilters(close)"
            >
              Clear Filters
            </b-button>
            <b-button
              variant="warning"
              @click="applyFilters(close)"
            >
              Apply
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { IfacSearchDropdown } from '@ifac/ui';
import { mapGetters } from 'vuex';
import FieldsOfInterest from './filtering-partials/FieldsOfInterest.vue';

export default {
  props: {
    currentFilters: {
      type: Object,
      default: () => ({
        countries: '',
        iags: '',
        fieldsOfInterest: [],
      }),
    },
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      iags: 'industryAcademiaGovernment/data',
    }),
    filters() {
      return this.currentFilters;
    },
    currentSelectedCountry() {
      return this.countries.find((i) => i.code === this.currentFilters.countries);
    },
    currentSelectedIag() {
      return this.iags.find((i) => i.id === this.currentFilters.iags);
    },
  },
  methods: {
    initFilters(close) {
      this.$emit('initFilters', close);
    },
    toggleInterest(interest) {
      if (!this.filters.fieldsOfInterest) {
        this.filters.fieldsOfInterest = [];
      }
      const exists = this.filters.fieldsOfInterest.findIndex((i) => i.id === interest.id);
      if (exists > -1) {
        this.filters.fieldsOfInterest.splice(exists, 1);
      } else {
        this.filters.fieldsOfInterest.push(interest);
      }
    },
    applyFilters(close) {
      this.$emit('filtersSet', this.filters);

      close();
    },
  },
  components: {
    IfacSearchDropdown,
    FieldsOfInterest,
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.filter-button {
  display: flex;
  align-items: center;
  font-size: inherit;
  line-height: unset;
  color: inherit;
  background: $color-grey-blue-lighter;
  border: 2px solid $color-grey-blue-light;
  border-radius: 0 0.25rem 0.25rem 0;
  padding: 0.65rem 0.75rem;

  svg {
    margin-left: 0.75rem;
  }
  &.btn-link {
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }

  @include media(tablet) {
    border: none;
    border-left: 1px solid $color-grey;
    padding: 0.4rem 0.75rem;
    .filter-button-text {
      display: none;
    }

    svg {
      margin-left: 0;
    }
  }
}

.modal-footer {
  .selected-filters-count {
    color: $color-grey-dark;
  }
  .btn {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>
