<template>
  <div class="container-fluid full-width-page-wrapper ifac-officials">
    <div>
      <h3 class="font-weight-bold page-title">IFAC Officials</h3>

      <div class="mb-4 border-0">
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-3" v-for="category in categories" :key="category.id">
            <b-card-header header-tag="header" class="p-1" role="tab"
                           :id="`category-${category.id}`">
              <b-button block v-b-toggle="`accordion-${category.id}`" variant="default">
                {{ category.name }}
              </b-button>
            </b-card-header>
            <b-collapse :visible="isCategoryVisible(category)"
                        :id="`accordion-${category.id}`"
                        @hide="handleHide(category)"
                        @shown="handleShown(category)"
                        v-slot="{visible}"
                        accordion="my-accordion" role="tabpanel">
              <component v-if="category.enabled && visible === true"
                         :is="category.component"
                         :category="category"/>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IfacDataTable, IfacLoader } from '@ifac/ui';
import OfficialsPublic from '@/services/Api/Public/Officials';

export default {
  components: {
    IfacDataTable,
    IfacLoader,
    awards: () => import('@/views/components/officials/Awards.vue'),
    affiliates: () => import('@/views/components/officials/Affiliates.vue'),
    'affiliates-with-triennial': () => import('@/views/components/officials/AffiliatesWithTriennial.vue'),
    'affiliates-with-selectors': () => import('@/views/components/officials/AffiliatesWithSelectors.vue'),
  },
  name: 'affiliates-officials',
  data() {
    return {
      categories: [],
      subCategory: null,
    };
  },
  async mounted() {
    await this.fetchOfficialsCategories();
    await this.scrollTo(`category-${this.$route.query.category}`);
  },
  methods: {
    isCategoryVisible(category) {
      if (category.id === 'awards' && this.$route.query.category?.startsWith('awards-')) {
        return true;
      }

      return this.$route.query.category === category.id;
    },
    handleHide() {
      this.$router
        .push({ path: '/affiliates/officials' })
        .catch(() => {
          // skip duplicated warning
        });
    },
    handleShown(category) {
      if (this.$route.query.category === category.id) {
         return;
      }

      this.$router
        .push({ path: '/affiliates/officials', query: { category: category.id } })
        .catch(() => {
          // skip duplicated warning
        });
    },
    async fetchOfficialsCategories() {
      const { data: { data } } = await OfficialsPublic.selectors('categories');

      this.categories = data;
    },
    scrollTo(id) {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
  },
};
</script>

<style>
.ag-theme-alpine .ag-body-viewport {
  height: auto;
}
</style>
