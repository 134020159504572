<template>
  <div class="container-fluid full-width-page-wrapper">
    <div>
      <h3 class="font-weight-bold page-title">Affiliates</h3>

      <div class="mb-4 border-0">
        <b-row>
          <b-col>
            <div class="search-filter-wrapper">
              <div class="search-icon-wrapper">
                <font-awesome-icon :icon="['far', 'magnifying-glass']"/>
              </div>
              <b-form-input
                v-model="search"
                class="search-input"
                placeholder="Search by name or keyword"
              ></b-form-input>
              <filtering
                :current-filters="filters"
                @filtersSet="setFilters"
                @initFilters="initFilters"
              ></filtering>
            </div>
            <p
              v-show="search.length > 0 && search.length < 3"
              class="mt-2 mb-0"
            >
              <small>Minimum of 3 characters required for search</small>
            </p>
          </b-col>
        </b-row>
      </div>
      <div class="shadow-none border-0">
        <b-row>
          <b-col>
            <p>
              Showing {{ items.length }} of {{ totalRows }} Affiliates {{ numberOfFiltersBeingApplied ? '(Some filters are active)' : '' }}
            </p>
          </b-col>
        </b-row>

        <ifac-data-table
          v-if="items.length > 0"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :total-rows="totalRows"
          @row-selected="rowSelected"
          @currentPageUpdated="currentPage = $event"
        />

        <ifac-loader v-if="busy"></ifac-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { IfacDataTable, IfacLoader } from '@ifac/ui';
import Affiliates from '@/services/Api/Affiliates';
import AffiliatesPublic from '@/services/Api/Public/Affiliates';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Filtering from './index-partials/Filtering.vue';

export default {
  name: 'Affiliates',
  data() {
    return {
      busy: false,
      search: '',
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      sortBy: null,
      direction: 'desc',
      filters: {
        countries: '',
        iags: '',
        fieldsOfInterest: [],
      },
      fields: [
        { key: 'avatar', label: '' },
        // A column that needs custom formatting
        { key: 'name', label: 'Full Name' },
        // A regular column
        { key: 'fieldsOfInterest', label: 'Fields of Interest' },
        { key: 'affiliation', label: 'Affiliation' },
        { key: 'country', label: 'Country / Region' },
      ],
      items: [],
      urlParams: {},
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath === '/affiliates') {
      this.initFilters(() => {});
      to = false;
    }

    next();
  },
  watch: {
    currentPage(val, old) {
      if (val !== old) {
        if (this.authed) {
          this.fetchAffiliates();
        } else {
          this.fetchAffiliatesPublic();
        }
      }
    },
    // eslint-disable-next-line
    search: _.debounce(function (term, old) {
      const minSearchTermLength = 3;
      if ((term.length >= minSearchTermLength && term !== old) || term.length === 0) {
        this.setFiltersToQuery();
        if (this.authed) {
          this.fetchAffiliates();
        } else {
          this.fetchAffiliatesPublic();
        }
      }
    }, 500),
  },
  computed: {
    ...mapGetters({
      authed: 'auth/isAuthenticated',
    }),
    authedUserName() {
      return this.$store.state.user.authed.email;
    },
    isLoading() {
      return this.$store.state.affiliates.loading;
    },
    count() {
      return this.$store.state.affiliates.data.length;
    },
    affiliates() {
      return this.$store.state.affiliates.data;
    },
    numberOfFiltersBeingApplied() {
      let numberOfFilters = 0;
      if (this.filters.countries !== '') {
        numberOfFilters++;
      }

      if (this.filters.fieldsOfInterest.length) {
        numberOfFilters++;
      }

      if (this.filters.iags !== '') {
        numberOfFilters++;
      }

      return numberOfFilters;
    },
  },
  methods: {
    initFilters(closeModal) {
      this.filters = {
        countries: '',
        iags: '',
        fieldsOfInterest: [],
      };

      this.setFiltersToQuery();

      if (this.authed) {
        this.fetchAffiliates();
      } else {
        this.fetchAffiliatesPublic();
      }

      closeModal();
    },
    rowSelected(row) {
      this.$router.push(`/affiliates/${row.id}`);
    },
    async fetchAffiliates() {
      const cleanFilters = this.cleanFilters();
      this.busy = true;
      this.items = [];
      const {
        data: {
          data: { items },
        },
        data: {
          data: { meta },
        },
      } = await Affiliates.get(
        this.currentPage,
        this.perPage,
        this.search,
        this.sortBy,
        this.direction,
        cleanFilters,
      );

      this.items = items;
      this.perPage = parseInt(meta.perPage, 10);
      this.currentPage = parseInt(meta.currentPage, 10);
      this.totalRows = parseInt(meta.total, 10);
      this.busy = false;
    },

    async fetchAffiliatesPublic() {
      const cleanFilters = this.cleanFilters();
      this.busy = true;
      const {
        data: {
          data: { items },
        },
        data: {
          data: { meta },
        },
      } = await AffiliatesPublic.get(
        this.currentPage,
        this.perPage,
        this.search,
        this.sortBy,
        this.direction,
        cleanFilters,
      );
      this.items = [];
      this.items = items;
      this.perPage = parseInt(meta.perPage, 10);
      this.currentPage = parseInt(meta.currentPage, 10);
      this.totalRows = parseInt(meta.total, 10);
      this.busy = false;
    },
    setFilters(filters) {
      console.log('setting filters');
      this.filters = filters;

      this.setFiltersToQuery();

      if (this.authed) {
        this.fetchAffiliates();
      } else {
        this.fetchAffiliatesPublic();
      }
    },
    setFiltersToQuery() {
      const cleanFilters = this.cleanFilters();

      const params = {};

      if (cleanFilters.countries) {
        params.country = cleanFilters.countries;
      }

      if (cleanFilters.iags) {
        params.iags = cleanFilters.iags;
      }

      if (cleanFilters.fieldsOfInterest.length > 0) {
        params.foi = cleanFilters.fieldsOfInterest.map((foi) => `TC${foi}`).join(',');
      }

      if (this.search) {
        params.search = this.search;
      }

      if (JSON.stringify(this.urlParams) === JSON.stringify(params)) {
        return;
      }

      // Update URL
      this.urlParams = params;

      this.$router.replace({
        query: params,
      }).catch();
    },
    cleanFilters() {
      const cleanFilters = Object.entries(this.filters).reduce(
        (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
        {},
      );
      cleanFilters.fieldsOfInterest = this.filters.fieldsOfInterest.map(
        (foi) => `${foi.ccNumber}.${foi.number}`,
      );

      return cleanFilters;
    },
    setFiltersFromQuery() {
      const {
        country, iags, foi, search,
      } = this.$route.query;

      this.urlParams = this.$route.query;

      if (country) {
        this.filters.countries = country;
      }

      if (iags) {
        this.filters.iags = iags;
      }

      if (foi) {
        const items = foi.split(',');
        items.forEach((item) => {
          const matches = item.match(/TC(\d+)\.(\d+)/);

          if (matches) {
            this.filters.fieldsOfInterest.push({
              ccNumber: parseInt(matches[1], 10),
              number: parseInt(matches[2], 10),
            });
          }
        });
      }

      if (search) {
        this.search = search;
      }
    },
  },
  created() {
    this.setFiltersFromQuery();

    // this.$store.dispatch('affiliates/fetchAffiliates');
    if (this.authed) {
      this.fetchAffiliates();
    } else {
      this.fetchAffiliatesPublic();
    }
  },
  components: {
    IfacDataTable,
    IfacLoader,
    Filtering,
  },
};
</script>
<style scoped lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.search-filter-wrapper {
  display: flex;
  align-items: center;
  background-color: $color-grey-lightest;
  border-radius: 0.25rem;
  color: $color-black-light;
  .search-icon-wrapper {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
    font-size: 24px;
  }
  .search-input {
    border: none;
    background-color: $color-grey-lightest;
    color: $color-grey-dark;
    padding: 0.65rem 0.75rem;
    font-size: 18px;
    &:focus {
      box-shadow: none;
    }
  }
  @include media(tablet) {
    .search-icon-wrapper {
      padding-right: 0;
    }
    .search-input {
      font-size: 16px;
      position: relative;
    }
  }
}
</style>
