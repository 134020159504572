import api from '@/services/Api';

class Officials {
  static selectors(type) {
    return api.get('/public/officials/selectors', {
      params: {
        type,
      },
    });
  }

  static affiliates(category, params) {
    return api.get('/public/officials/affiliates', {
      params: {
        category,
        ...params,
      },
    });
  }
}

export default Officials;
