import api from '@/services/Api';
import { cleanObject } from '@ifac/ui';

class Affiliates {
  /**
   * Fetch all
   * @returns {AxiosPromise<any>}
   */
  static get(
    page = 1,
    perPage = 12,
    search = '',
    sortBy = null,
    direction = 'desc',
    filter = null,
  ) {
    page = parseInt(page, 10);
    perPage = parseInt(perPage, 10);

    // Clean up the empty fields being sent
    const params = cleanObject({
      page,
      perPage,
      sortBy,
      direction,
      search,
      filter,
    });

    return api.get('/public/affiliates', {
      params,
    });
  }

  /**
   * Fetch single
   * @returns {AxiosPromise<any>}
   */
  static show(id) {
    return api.get(`/public/affiliates/${id}`);
  }
}

export default Affiliates;
